import SmoothScroll from 'smooth-scroll'
import $ from 'jquery';
import 'slick-carousel';
import slider from './slider';
// import postFetch from './postFetch'

new SmoothScroll('a[href*="#"]', {
	speed: 900,
	speedAsDuration: true
})

slider()
// postFetch()

const fixedCta = document.getElementById('fixed-cta');

window.addEventListener('scroll', () => {
	if(400 < scrollY && scrollY < document.body.clientHeight - (screen.height + 200)) {
		fixedCta.style.opacity = '1.0';
		fixedCta.style.zIndex = '999';
	} else {
		fixedCta.style.opacity = '0';
		fixedCta.style.zIndex = '-1';
	}
});

$('.section2-slider').slick({
	dots: true,
  	infinite: true,
  	speed: 300,
  	slidesToShow: 1,
	nextArrow: $('.slider-next'),
	prevArrow: $('.slider-prev'),
});

$('.section2-slider').on('afterChange', (e, s, c) => {
	$('.slide-number').text(`0${c + 1}`);
})

$(function () {
  $('.js-slick-slider').slick({
    arrows: false, 
    dots: true, 
  });
});