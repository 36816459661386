export default function slider() {
    const sliderImg = document.getElementById('slider-img')
    const sliderText1 = document.getElementById('text1')
    const sliderText2 = document.getElementById('text2')
    const sliderText3 = document.getElementById('text3')
    const controller1 = document.getElementById('controller-1')
    const controller2 = document.getElementById('controller-2')
    const controller3 = document.getElementById('controller-3')
    const text1 = ['<span class="eng">Case1</span>Beauty Design Salon lucir', '<span class="eng">Case2</span>MELLOW WAX 宮崎店', '<span class="eng">Case3</span>Eyelash & Nail I・feliz × feliz']
    const text2 = ['売上が120%アップ！', 'スクールの受講で提案の幅が広がりました', 'お客様からの嬉しい声がたくさん届いています']
    const text3 = [
        `ワックス脱毛サロンなので、アイブロウデザインはメニューとして、お客様に提供していたのですが、眉毛パーマの導入はしていませんでした。<br>
        なので、ミラノスタイルブロウリフトの情報をインターネットで見つけて、口コミなどが高かったので受講を決めました。
        眉毛パーマの提供を始めてから単価が上がり、全体の売上が120%アップし、リピーターも増えました！`,
        `「眉毛パーマ(MSB)」と「アイブロウデザイン」の2つを学べることに魅力を感じて受講を決めました！<br>
        お客様の眉毛の状況により、眉毛パーマの施術ができない場合もあります。ですが、アイブロウデザインの施術は、できる場合があるので眉毛パーマとアイブロウデザインの両方を学ぶことで、お客様へのご提案の幅が広くなりました。その結果、お客様にもより安心していただけるカウンセリングが提供できるようになりました。`,
        `以前、他のスクールを受講してメニューを作りましたが、赤みが出ることが多く、お客様のリピートがない状態でした。<br>
        ミラノスタイルブロウリフトの本部に電話して相談したところ、本部は世界でも有数の脱毛ワックスの日本本部でもあり、運営しているサロンの口コミも高かったので、受講を決めました。ここまで丁寧に教えてくれるスクールは、初めてでした。<br>
        今では、お客様から高い評価を頂いています！次は、フェイス脱毛を受講したいと思っています！`
    ]

    controller1.addEventListener('click', (e)=>{
        sliderImg.setAttribute('src', './assets/images/case-lucir.webp')
        sliderImg.setAttribute('alt', 'Beauty Design Salon lucir')
        sliderText1.innerHTML = text1[0]
        sliderText2.innerHTML = text2[0]
        sliderText3.innerHTML = text3[0]
        controller1.classList.add('active')
        controller2.classList.remove('active')
        controller3.classList.remove('active')
    })

    controller2.addEventListener('click', (e)=>{
        sliderImg.setAttribute('src', './assets/images/case-miyazaki.webp')
        sliderImg.setAttribute('alt', 'MELLOW WAX 宮崎店')
        sliderText1.innerHTML = text1[1]
        sliderText2.innerHTML = text2[1]
        sliderText3.innerHTML = text3[1]
        controller2.classList.add('active')
        controller1.classList.remove('active')
        controller3.classList.remove('active')

    })

    controller3.addEventListener('click', (e)=>{
        sliderImg.setAttribute('src', './assets/images/case-ifeliz.webp')
        sliderImg.setAttribute('alt', 'Eyelash & Nail I・feliz × feliz')
        sliderText1.innerHTML = text1[2]
        sliderText2.innerHTML = text2[2]
        sliderText3.innerHTML = text3[2]
        controller3.classList.add('active')
        controller1.classList.remove('active')
        controller2.classList.remove('active')
    })
}